'use client';

import { useTheme } from 'next-themes';
import { brandName } from '../../constants/constants';

const DriftTextLogo = (props: React.HTMLAttributes<HTMLOrSVGElement>) => {
	const { theme } = useTheme();

	return (
		<img
			style={{ minWidth: '56px' }}
			src={`/assets/icons/text-logo-${theme ?? 'dark-theme'}.svg`}
			sizes="66px"
			alt={brandName}
			className="w-[66px] h-full"
			{...props}
		></img>
	);
};

export default DriftTextLogo;
