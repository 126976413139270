import { Phoenix } from '@drift-labs/icons';
import OpenBookIcon from '../Icons/OpenBookIcon';
import Text from '../Text/Text';

const PoweredByOpenBookAndPhoenix = () => {
	return (
		<div className="flex flex-row items-center justify-center">
			<Text.BODY2>
				Powered by{' '}
				<a
					href="https://twitter.com/phoenixtrade"
					target="_blank"
					rel="noreferrer"
				>
					<div className="w-4 h-4 rounded-full rounded border border-interactive-link inline-block relative top-[3px]">
						<div className="relative top-[-1px] left-[-1px]">
							<Phoenix color="var(--interactive-link)" width={14} height={14} />
						</div>
					</div>{' '}
					Phoenix
				</a>{' '}
				and{' '}
				<a
					href="https://twitter.com/openbookdex"
					target="_blank"
					rel="noreferrer"
				>
					<div className="w-4 h-4 rounded-full rounded border border-interactive-link inline-block relative top-[3px]">
						<OpenBookIcon
							color="var(--interactive-link)"
							width="14"
							height="14"
						/>
					</div>{' '}
					Openbook
				</a>
			</Text.BODY2>
		</div>
	);
};

export default PoweredByOpenBookAndPhoenix;
