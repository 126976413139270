import React from 'react';
import { ConfiguableSvgProps } from './types';

const OpenBookIcon = ({
	width = '16',
	height = '16',
	...props
}: ConfiguableSvgProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.61011 3.20768L7.49393 3.77012C7.52278 3.78847 7.54025 3.82029 7.54025 3.85448V12.0567C7.54025 12.0894 7.52425 12.12 7.49742 12.1387L6.6136 12.7547C6.54731 12.801 6.45642 12.7535 6.45642 12.6727V3.29205C6.45642 3.21314 6.54354 3.16532 6.61011 3.20768ZM5.4824 4.29721L4.33583 3.70994C4.26929 3.67586 4.19024 3.72418 4.19024 3.79895V12.1388C4.19024 12.2144 4.27091 12.2627 4.33753 12.2269L5.4841 11.6117C5.51656 11.5943 5.53681 11.5604 5.53681 11.5236V4.38621C5.53681 4.34869 5.5158 4.31432 5.4824 4.29721ZM3.13806 4.85994L1.466 4.27326C1.40097 4.25044 1.33289 4.2987 1.33289 4.36762V11.6299C1.33289 11.7014 1.40578 11.7498 1.4717 11.722L3.14376 11.018C3.18084 11.0024 3.20495 10.9661 3.20495 10.9258V4.9543C3.20495 4.91184 3.17813 4.874 3.13806 4.85994ZM9.38999 3.20768L8.50617 3.77012C8.47732 3.78847 8.45985 3.82029 8.45985 3.85448V12.0567C8.45985 12.0894 8.47584 12.12 8.50267 12.1387L9.3865 12.7547C9.45279 12.801 9.54368 12.7535 9.54368 12.6727V3.29205C9.54368 3.21314 9.45656 3.16532 9.38999 3.20768ZM10.5177 4.29721L11.6643 3.70994C11.7308 3.67586 11.8099 3.72418 11.8099 3.79895V12.1388C11.8099 12.2144 11.7292 12.2627 11.6626 12.2269L10.516 11.6117C10.4835 11.5943 10.4633 11.5604 10.4633 11.5236V4.38621C10.4633 4.34869 10.4843 4.31432 10.5177 4.29721ZM12.862 4.85994L14.5341 4.27326C14.5991 4.25044 14.6672 4.2987 14.6672 4.36762V11.6299C14.6672 11.7014 14.5943 11.7498 14.5284 11.722L12.8563 11.018C12.8193 11.0024 12.7951 10.9661 12.7951 10.9258V4.9543C12.7951 4.91184 12.822 4.874 12.862 4.85994Z"
				fill={props.color}
			/>
		</svg>
	);
};

export default OpenBookIcon;
