'use client';

import { L2OrderBook, MarketType } from '@drift-labs/sdk';
import useMarketStateStore from '../stores/useMarketStateStore';
import { MarketId } from '@drift/common';

const DEFAULT_L2_STATE: L2OrderBook = { asks: [], bids: [] };

const useL2StateForMarket = (
	marketIndex: number,
	marketType: MarketType
): L2OrderBook => {
	const dlobState = useMarketStateStore();

	if (marketIndex === undefined || !marketType) {
		return DEFAULT_L2_STATE;
	}

	const l2State = dlobState.getDlobStateForMarket(
		new MarketId(marketIndex, marketType)
	);

	return l2State;
};

export default useL2StateForMarket;
