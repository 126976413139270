import { ChevronRight } from '@drift-labs/icons';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import UI_UTILS from '../../utils/uiUtils';

const Link = (
	props: PropsWithChildren<{
		href?: string;
		className?: string;
		lazyHref?: () => string;
		withChevron?: boolean;
	}>
) => {
	return (
		<a
			href={props.lazyHref ? undefined : `${props.href}`}
			onClick={
				props.lazyHref
					? () => {
							UI_UTILS.openUrl(props.lazyHref());
					  }
					: undefined
			}
			target="_blank"
			rel="noopener noreferrer"
			className={twMerge(
				`text-interactive-link hover:cursor-pointer`,
				props.className ?? ``
			)}
		>
			{props.withChevron ? (
				<div className="flex items-center space-x-1">
					<span>{props.children}</span>
					<ChevronRight className="mb-0.5" color="currentColor" />
				</div>
			) : (
				<div>{props.children}</div>
			)}
		</a>
	);
};

export default Link;
