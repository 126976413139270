'use client';

import React from 'react';
import { twMerge } from 'tailwind-merge';
import useDevStore from '../../stores/useDevStore';
import useMarketStateStore, {
	DlobListeningSelection,
} from '../../stores/useMarketStateStore';
import Switch from '../Switch';
import { OrderbookDisplayProps } from '../Orderbook/OrderbookDisplay';
import Text from '../Text/Text';
import Select from '../Inputs/Select';
import Tooltip from '../Tooltip/Tooltip';
import { MarketId } from '@drift/common';
import { useTargetMarketSlotStaleness } from 'src/hooks/useTargetMarketSlotStaleness';
import { DriftWindow } from 'src/window/driftWindow';

const DATASOURCE_OPTIONS = [
	{
		value: DlobListeningSelection.BLOCKCHAIN,
		label: DlobListeningSelection.BLOCKCHAIN,
	},
	{
		value: DlobListeningSelection.DLOB_SERVER_POLLING,
		label: DlobListeningSelection.DLOB_SERVER_POLLING,
	},
	{
		value: DlobListeningSelection.DLOB_SERVER_WEBSOCKET,
		label: DlobListeningSelection.DLOB_SERVER_WEBSOCKET,
	},
];

const DevOrderbookPanel = (props: OrderbookDisplayProps) => {
	const { set, dlobSettings } = useDevStore();

	const toggleDlobSetting = (val: keyof typeof dlobSettings) => {
		set((s) => {
			s.dlobSettings[val] = !s.dlobSettings[val];
		});
	};

	const setListeningSelection = useMarketStateStore(
		(s) => s.setListeningSelection
	);
	const setDataSource = (
		marketId: MarketId,
		newSelection: DlobListeningSelection
	) => {
		DriftWindow.DISABLE_AUTO_SWITCH_DLOB_SOURCE = true;

		setListeningSelection(marketId, newSelection);
	};

	const dataSource = useMarketStateStore(
		(s) => s.marketSubscriptionState?.[props?.marketId?.key]?.listeningSelection
	);

	const marketSlotStaleness = useTargetMarketSlotStaleness(props.marketId);

	return (
		<div className="flex flex-col w-full">
			<div className="flex flex-col space-y-1">
				<div className="flex flex-col">
					<div className="flex justify-between w-full space-x-2">
						<div
							className={twMerge(
								marketSlotStaleness <= 0 && 'text-positive-green',
								marketSlotStaleness > 1 && 'text-warn-yellow',
								marketSlotStaleness > 4 && 'text-negative-red'
							)}
						>
							<span>
								SLOT STALENESS:{' '}
								{marketSlotStaleness == undefined
									? 'Invalid'
									: marketSlotStaleness}
							</span>
						</div>
					</div>
				</div>
				<div className="flex flex-col">
					<div className="flex flex-col space-y-1">
						<div className="flex flex-col">
							<div className="flex space-x-1">
								<Text.MICRO1>ORDERBOOK DATA SOURCE</Text.MICRO1>
								<Tooltip content="This will be the source of the orderbook and oracle price used in liquidty+trading calculations" />
							</div>
							<span>
								<Select.Form
									id="devswitch_datasource_selection"
									options={DATASOURCE_OPTIONS}
									selection={dataSource}
									onChange={(newSelection) => {
										setDataSource(props.marketId, newSelection);
									}}
								/>
							</span>
						</div>
					</div>
				</div>

				<div className="flex flex-col">
					<Text.MICRO1>LIQUIDITY DISPLAY SOURCES</Text.MICRO1>
					<div className="flex space-x-4">
						<div className="flex space-x-1">
							<Text.MICRO1 className="text-text-default">DLOB</Text.MICRO1>
							<Switch
								checked={dlobSettings.showDlob}
								onChange={() => {
									toggleDlobSetting('showDlob');
								}}
							/>
						</div>
						{props.marketId.isPerp ? (
							<div className="flex space-x-1">
								<Text.MICRO1 className="text-text-default">VAMM</Text.MICRO1>
								<Switch
									checked={dlobSettings.showVamm}
									onChange={() => {
										toggleDlobSetting('showVamm');
									}}
								/>
							</div>
						) : (
							<>
								<div className="flex space-x-1">
									<span>openbook</span>
									<Switch
										checked={dlobSettings.showSerum}
										onChange={() => {
											toggleDlobSetting('showSerum');
										}}
									/>
								</div>
								<div className="flex space-x-1">
									<span>phoenix</span>
									<Switch
										checked={dlobSettings.showPhoenix}
										onChange={() => {
											toggleDlobSetting('showPhoenix');
										}}
									/>
								</div>
							</>
						)}
					</div>
					<div className="flex space-x-1">
						<span>uncross</span>
						<Switch
							checked={!dlobSettings.disableUncross}
							onChange={() => {
								toggleDlobSetting('disableUncross');
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DevOrderbookPanel;
