import { MarketType } from '@drift-labs/sdk';
import { ENUM_UTILS } from '@drift/common';
import produce from 'immer';
import { create } from 'zustand';
import { PerpMarketInfo, SpotMarketInfo } from './types';

export interface MarketsInfoStore {
	set: (x: (s: MarketsInfoStore) => void) => void;
	get: () => MarketsInfoStore;
	initialized: boolean; // Whether the market information has been fetched yet
	allMarketsInfo: {
		allMarketsList: (PerpMarketInfo | SpotMarketInfo)[];
		predictionList: PerpMarketInfo[]; // List of all prediction markets
		perpList: PerpMarketInfo[]; // List of all perp markets
		spotList: SpotMarketInfo[]; // List of all spot markets
		perpLookup: PerpMarketInfo[]; // List of perp markets, where markets are stored in same index as their market index (some entries may be empty)
		spotLookup: SpotMarketInfo[]; // List of spot markets, where markets are stored in same index as their market index (some entries may be empty)
		lookup: { [key: string]: PerpMarketInfo | SpotMarketInfo }; // Lookup of all markets, keyed by symbol
	};
	getMarketInfoByIndexAndType: (
		marketIndex: number,
		marketType: MarketType
	) => PerpMarketInfo | SpotMarketInfo;
	getMarketInfoBySymbol: (symbol: string) => PerpMarketInfo | SpotMarketInfo;
}

const useMarketsInfoStore = create<MarketsInfoStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	getMarketInfoByIndexAndType: (index, type) => {
		if (ENUM_UTILS.match(type, MarketType.PERP)) {
			const lookupList = get().allMarketsInfo.perpLookup;
			const marketInfo = lookupList[index];
			//if (!marketInfo) throw new Error('Market does not exist');
			return marketInfo;
		}

		if (ENUM_UTILS.match(type, MarketType.SPOT)) {
			const lookupList = get().allMarketsInfo.spotLookup;
			const marketInfo = lookupList[index];
			//if (!marketInfo) throw new Error('Market does not exist');
			return marketInfo;
		}

		throw new Error('Unmatched market type');
	},
	getMarketInfoBySymbol: (symbol) => get().allMarketsInfo.lookup[symbol],
	initialized: false,
	allMarketsInfo: {
		allMarketsList: [],
		predictionList: [],
		perpList: [],
		spotList: [],
		perpLookup: [],
		spotLookup: [],
		lookup: {},
	},
}));

export default useMarketsInfoStore;
