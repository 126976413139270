import {
	BN,
	BigNum,
	L2Level,
	L2OrderBook,
	PRICE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import { COMMON_UTILS } from '@drift/common';
import {
	OrderBookBidAsk,
	OrderBookDisplayState,
	OrderBookDisplayStateBidAsk,
} from './OrderbookTypes';

const roundForOrderbook = (num: number) => Number(num.toFixed(6));

export const getBucketFloorForPrice = (price: number, groupingSize: number) => {
	const priceIsNegative = price < 0;

	if (COMMON_UTILS.dividesExactly(price, groupingSize)) {
		return roundForOrderbook(price);
	}

	const amountToDeduct = priceIsNegative
		? groupingSize - (Math.abs(price) % groupingSize)
		: Math.abs(price) % groupingSize;

	const floorPrice = price - amountToDeduct;

	return roundForOrderbook(floorPrice);
};

const getBucketCeilingForPrice = (price: number, groupingSize: number) => {
	return getBucketFloorForPrice(price + groupingSize, groupingSize);
};

export const getBucketAnchorPrice = (
	type: 'bid' | 'ask',
	price: number,
	groupingSize: number
) => {
	// If the grouping size matches exactly then the anchor price should be the same as the floor price regardless
	if (COMMON_UTILS.dividesExactly(price, groupingSize)) {
		return getBucketFloorForPrice(price, groupingSize);
	}

	if (type === 'bid') {
		return getBucketFloorForPrice(price, groupingSize);
	} else {
		return getBucketCeilingForPrice(price, groupingSize);
	}
};

export const getBucketForUserLiquidity = (
	price: number,
	groupingSize: number,
	type: 'bid' | 'ask'
) => {
	return getBucketAnchorPrice(type, price, groupingSize);
};

const formatLevelsToDisplay = (
	levels: L2Level[],
	basePrecision: BN
): {
	formattedLevels: OrderBookDisplayState['asks'];
	bestLevel: number;
	cumulativeSize: number;
} => {
	const cumulativeSizes: OrderBookDisplayStateBidAsk['cumulativeSize'] = {
		dlob: 0,
		vamm: 0,
		serum: 0,
		phoenix: 0,
		openbook: 0,
	};

	const sizeToNum = (size: BN) => BigNum.from(size, basePrecision).toNum();

	const formattedLevels = levels.map((level) => {
		const dlobSize = sizeToNum(level?.sources?.dlob ?? ZERO);
		const vammSize = sizeToNum(level?.sources?.vamm ?? ZERO);
		const serumSize = sizeToNum(level?.sources?.serum ?? ZERO);
		const phoenixSize = sizeToNum(level?.sources?.phoenix ?? ZERO);
		const openbookSize = sizeToNum(level?.sources?.openbook ?? ZERO);

		const bucketSize =
			dlobSize + vammSize + serumSize + phoenixSize + openbookSize;

		cumulativeSizes.dlob += dlobSize;
		cumulativeSizes.vamm += vammSize;
		cumulativeSizes.serum += serumSize;
		cumulativeSizes.phoenix += phoenixSize;
		cumulativeSizes.openbook += openbookSize;

		return {
			price: BigNum.from(level.price, PRICE_PRECISION_EXP).toNum(),
			size: bucketSize,
			cumulativeSize: { ...cumulativeSizes },
		};
	});

	return {
		formattedLevels,
		bestLevel: formattedLevels?.[0]?.price,
		cumulativeSize: Object.values(cumulativeSizes).reduce(
			(total, current) => total + current,
			0
		),
	};
};

export const formatL2ToDisplay = (
	l2State: L2OrderBook,
	basePrecision: BN
): OrderBookDisplayState => {
	const formattedBids = formatLevelsToDisplay(l2State.bids, basePrecision);
	const formattedAsks = formatLevelsToDisplay(l2State.asks, basePrecision);

	return {
		bids: formattedBids.formattedLevels,
		asks: formattedAsks.formattedLevels,
		bidTotalSize: formattedBids.cumulativeSize,
		askTotalSize: formattedAsks.cumulativeSize,
	};
};

export const mergeBidsAndAsksForGroupsize = (
	type: 'bid' | 'ask',
	bidsAsks: OrderBookBidAsk[],
	groupingSize: number
): OrderBookBidAsk[] => {
	const bidsAsksBucketLookup = new Map<number, OrderBookBidAsk>();

	for (const bidAsk of bidsAsks) {
		const anchorPrice = getBucketAnchorPrice(type, bidAsk.price, groupingSize);
		const existingBucket = bidsAsksBucketLookup.get(anchorPrice);
		if (existingBucket) {
			existingBucket.size += bidAsk.size;
		} else {
			bidsAsksBucketLookup.set(anchorPrice, { ...bidAsk });
		}
	}

	return Array.from(bidsAsksBucketLookup.values());
};
