'use client';

import { MarketType } from '@drift-labs/sdk';
import { ENUM_UTILS } from '@drift/common';
import { useEffect, useState } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';

/**
 * Pull 24h market data from store
 * - Can optionally filter by market type
 * - And then further by market type and market index
 * @param targetMarket
 * @returns
 */
const use24hMarketDataFromStore = (targetMarket?: {
	targetMarketType: MarketType;
	targetMarketIndex?: number;
}) => {
	const marketDataInStore = useDriftStore((s) => s.marketsData24H);

	const [marketDataToReturn, setMarketDataToReturn] = useState<
		typeof marketDataInStore
	>([]);

	useEffect(() => {
		if (!targetMarket) {
			setMarketDataToReturn([...marketDataInStore]);
			return;
		}

		const newMarketDataToReturn = marketDataInStore.filter((market) => {
			if (!ENUM_UTILS.match(market.marketType, targetMarket.targetMarketType))
				return false;

			if (
				targetMarket.targetMarketIndex !== undefined &&
				market.marketIndex !== targetMarket.targetMarketIndex
			)
				return false;

			return true;
		});

		setMarketDataToReturn(newMarketDataToReturn);
	}, [
		marketDataInStore,
		targetMarket?.targetMarketIndex,
		ENUM_UTILS.toStr(targetMarket?.targetMarketType),
	]);

	return marketDataToReturn;
};

export default use24hMarketDataFromStore;
