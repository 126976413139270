'use client';

import { singletonHook } from 'react-singleton-hook';
import { useLocalStorageState } from '../../utils/localStorageUtils';

export enum ORDERBOOK_DISPLAY_TYPE {
	VERTICAL = 'VERTICAL',
	HORIZONTAL = 'HORIZONTAL',
}

const useOrderbookDisplayPreference = () => {
	const state = useLocalStorageState(
		'orderbook_display',
		ORDERBOOK_DISPLAY_TYPE.VERTICAL
	);

	return state as [
		ORDERBOOK_DISPLAY_TYPE,
		(newVal: ORDERBOOK_DISPLAY_TYPE) => void
	];
};

export default singletonHook(
	[ORDERBOOK_DISPLAY_TYPE.HORIZONTAL, () => {}],
	useOrderbookDisplayPreference
);
