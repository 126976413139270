'use client';

import useDriftStore from '../stores/DriftStore/useDriftStore';
import useMarkPrice from './useMarkPrice';

const useCurrentMarketMarkPrice = () => {
	const currentMarketId = useDriftStore((s) => s.selectedMarket.marketId);

	const currentMarketPrice = useMarkPrice(currentMarketId);

	return currentMarketPrice;
};

export default useCurrentMarketMarkPrice;
