import { MarketType } from '@drift-labs/sdk';
import { PropWithUpdateCallback } from '../../hooks/usePropWithUpdateCallback';
import { MarketPriceState } from '../../@types/types';
import { MarketId, UISerializableOrderActionRecord } from '@drift/common';

export type OrderbookDisplayProps = {
	marketId: MarketId;
	markPrice: number;
	orderbookDisplayState: OrderBookDisplayState;
	depth: number;
	groupingSizeOptions: number[];
	groupingSizeSelection: PropWithUpdateCallback<number>;
	groupingType: GROUPING_TYPE;
	currentUserBidsAndAsks: BidsAndAsks;
	loading: boolean;
	marketPriceState: MarketPriceState;
	latestMarketTrades: UISerializableOrderActionRecord[];
	isDisplayingOrderbook: boolean;
	setIsDisplayingOrderbook: (newVal: boolean) => void;
};

export type CategorisedLiquidity = Record<LiquidityType, number>;

export enum GROUPING_TYPE {
	PCT,
	QUOTE,
}

export type LiquidityType = 'vamm' | 'dlob' | 'serum' | 'phoenix' | 'openbook';

export type OrderBookDisplayState = {
	bids: OrderBookDisplayStateBidAsk[];
	asks: OrderBookDisplayStateBidAsk[];
	bidTotalSize: number;
	askTotalSize: number;
};

export type OrderBookDisplayStateBidAsk = {
	price: number;
	size: number;
	cumulativeSize: CategorisedLiquidity;
};

export type OrderBookBidAsk = {
	price: number;
	size: number;
	type: LiquidityType;
};

export type BidsAndAsks = {
	bids: OrderBookBidAsk[];
	asks: OrderBookBidAsk[];
	bestBid: number;
	bestAsk: number;
	marketIndex: number;
	marketType: MarketType;
};
