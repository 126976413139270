import produce from 'immer';
import { create } from 'zustand';
import { UISerializableOrderActionRecord } from '@drift/common';
export interface DevStore {
	set: (x: (s: DevStore) => void) => void;
	get: () => DevStore;
	dlobSettings: {
		showVamm: boolean;
		showDlob: boolean;
		showSerum: boolean;
		showPhoenix: boolean;
		disableUncross: boolean;
	};
	triggerBadRpcHealth: boolean;
	showRefCode: boolean;
	showInfoForTrade?: UISerializableOrderActionRecord;
	pausedRecentTradeKeys?: Record<string, true>;
}

const useDevStore = create<DevStore>(
	(set, get): DevStore => ({
		set: (fn) => set(produce(fn)),
		get: () => get(),
		dlobSettings: {
			showVamm: true,
			showDlob: true,
			showSerum: true,
			showPhoenix: true,
			disableUncross: false,
		},
		triggerBadRpcHealth: false,
		showRefCode: false,
		showInfoForTrade: undefined,
		pausedRecentTradeKeys: {},
	})
);

export default useDevStore;
