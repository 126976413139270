'use client';

import { SlotSubscriber } from '@drift-labs/sdk';
import {
	createContext,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftClientIsReady from '../hooks/useDriftClientIsReady';
import { Subject } from 'rxjs';
import { Commitment } from '@solana/web3.js';

const currentSlotSubject = new Subject<number>();
export const SlotContext = createContext({
	commitment: 'recent' as Commitment,
	currentSlot: 0,
	subject: currentSlotSubject,
	subscribe: () => {
		return () => {};
	},
});

const SLOT_SUBCRIBER_RESUB_TIMEOUT_MS = 2000;

const CurrentSlotProvider = (props: PropsWithChildren<any>) => {
	const [currentSlot, setCurrentSlot] = useState(0);
	const connection = useDriftStore((s) => s.connection.current);
	const isConnected = useDriftClientIsReady();

	const subscriber = useRef<SlotSubscriber>(undefined);

	const subscribe = useCallback(() => {
		if (!isConnected || !connection) return () => {};

		if (subscriber.current) {
			subscriber.current.unsubscribe();
		}

		subscriber.current = new SlotSubscriber(connection, {
			resubTimeoutMs: SLOT_SUBCRIBER_RESUB_TIMEOUT_MS,
		});

		(async () => {
			await subscriber.current.subscribe();
			if (subscriber.current) {
				setCurrentSlot(subscriber.current.getSlot());
			}
		})();

		subscriber.current.eventEmitter.on('newSlot', (newSlot) => {
			setCurrentSlot(newSlot);
			currentSlotSubject.next(newSlot);
		});

		return () => {
			subscriber.current.unsubscribe();
			subscriber.current = undefined;
		};
	}, [connection, connection?.commitment, isConnected]);

	useEffect(() => {
		const cleanup = subscribe();

		return () => {
			cleanup();
		};
	}, [subscribe]);

	const returnValue = useMemo(() => {
		return {
			currentSlot,
			subject: currentSlotSubject,
			commitment: connection?.commitment,
			subscribe,
		};
	}, [currentSlotSubject, currentSlot]);

	return <SlotContext value={returnValue}>{props.children}</SlotContext>;
};

export default CurrentSlotProvider;
