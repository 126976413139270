'use client';

import { useContext } from 'react';
import { SlotContext } from 'src/providers/currentSlotProvider';
import { MarketId } from '@drift/common';
import useMarketStateStore from 'src/stores/useMarketStateStore';

export const useTargetMarketSlotStaleness = (marketId: MarketId) => {
	const l2currentSlot =
		useMarketStateStore()?.getMarketDataForMarket?.(marketId)?.derivedState
			?.updateSlot;

	const currentSlotContext = useContext(SlotContext);

	return currentSlotContext.currentSlot - l2currentSlot;
};
