'use client';

import { BigNum, MarketType, PRICE_PRECISION_EXP } from '@drift-labs/sdk';
import { MarketId } from '@drift/common';
import { useMemo } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useMarketStateStore from '../stores/useMarketStateStore';

const DEFAULT_VALUE = BigNum.zero(PRICE_PRECISION_EXP);

const useMarkPrice = (market?: MarketId): BigNum => {
	const currentlySelectedMarketConfig = useDriftStore(
		(s) => s.selectedMarket.current
	);

	const marketId = useMemo(() => {
		const marketId =
			market?.marketIndex ??
			currentlySelectedMarketConfig?.market?.marketIndex ??
			0;
		const marketType =
			market?.marketType ??
			currentlySelectedMarketConfig?.marketType ??
			MarketType.PERP;
		return new MarketId(marketId, marketType);
	}, [market, currentlySelectedMarketConfig]);

	const markPrice =
		useMarketStateStore((s) => {
			return !s.marketDataState[marketId.key]?.derivedState?.markPrice?.eqZero()
				? s.marketDataState[marketId.key]?.derivedState?.markPrice
				: s.marketDataState[marketId.key]?.oracle?.price;
		}) ?? DEFAULT_VALUE;

	return markPrice;
};

export default useMarkPrice;
