import React from 'react';
import { twMerge } from 'tailwind-merge';

export type SEMANTIC_STATUS = 'green' | 'amber' | 'red';

const SemanticChip = ({
	status,
	className,
}: {
	status: 'green' | 'amber' | 'red' | 'grey';
	className?: string;
}) => {
	return (
		<div
			className={twMerge(
				`block h-2 w-2 rounded-full`,
				status === 'green'
					? `bg-positive-green`
					: status === 'amber'
					? 'bg-brand-yellow'
					: status === 'grey'
					? 'bg-text-label'
					: `bg-negative-red`,
				className
			)}
		/>
	);
};

export default SemanticChip;
