import React, { PropsWithChildren, memo } from 'react';
import Text from 'src/components/Text/Text';
import { twMerge } from 'tailwind-merge';

const TradeFormInputLabel = (
	props: PropsWithChildren<{ className?: string; allowPointer?: boolean }>
) => {
	return (
		<div
			className={twMerge(
				`text-text-tertiary font-normal`,
				!props.allowPointer && 'pointer-events-none select-none',
				props.className
			)}
		>
			<Text.BODY3 className={props.className}>{props.children}</Text.BODY3>
		</div>
	);
};

export default memo(TradeFormInputLabel);
