'use client';

import useCurrentWalletAdapter from './useCurrentWalletAdapter';

const useCurrentAuthority = () => {
	const currentWallet = useCurrentWalletAdapter();

	return currentWallet.publicKey;
};

export default useCurrentAuthority;
