'use client';

import {
	ContractTier,
	MarketType,
	OracleSource,
	PerpMarketAccount,
} from '@drift-labs/sdk';
import { matchEnum } from '@drift/common';
import { useEffect, useState } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import { PerpMarketInfo, SpotMarketInfo } from '../stores/types';
import useMarketsInfoStore from '../stores/useMarketsInfoStore';
import { singletonHook } from 'react-singleton-hook';

export const marketHasInsuranceFund = (marketAccount: PerpMarketAccount) => {
	const hasNoInsuranceFund =
		matchEnum(
			(marketAccount as PerpMarketAccount).contractTier,
			ContractTier.SPECULATIVE
		) ||
		matchEnum(
			(marketAccount as PerpMarketAccount).contractTier,
			ContractTier.ISOLATED
		);

	return !hasNoInsuranceFund;
};

export type MarketInfo = {
	hasInsuranceFund: boolean;
	showInsuranceFundWarning: boolean;
	isDriftSpotMarket: boolean;
	type: MarketType;
	info: PerpMarketInfo | SpotMarketInfo;
};

const DEFAULT_STATE: MarketInfo = {
	hasInsuranceFund: false,
	showInsuranceFundWarning: false,
	isDriftSpotMarket: false,
	type: MarketType.PERP,
	info: undefined as PerpMarketInfo,
};

/**
 * This hook keeps the full info (config, account state) for all of the perp and spot markets up to date in the store
 */
const useInfoForCurrentlySelectedMarket = () => {
	const allMarketsInfo = useMarketsInfoStore();
	const currentlySelectedMarket = useDriftStore(
		(s) => s.selectedMarket.current
	);

	const [currentMarketInfo, setCurrentMarketInfo] = useState(DEFAULT_STATE);

	useEffect(() => {
		if (!currentlySelectedMarket) {
			setCurrentMarketInfo(DEFAULT_STATE);
			return;
		}

		if (!allMarketsInfo.initialized) {
			setCurrentMarketInfo(DEFAULT_STATE);
			return;
		}

		const marketType = currentlySelectedMarket.marketType;
		const marketIndex = currentlySelectedMarket.marketIndex;

		const info = allMarketsInfo.getMarketInfoByIndexAndType(
			marketIndex,
			marketType
		);

		if (!info) {
			setCurrentMarketInfo(DEFAULT_STATE);
			return;
		}

		const isSpot = matchEnum(marketType, MarketType.SPOT);

		const hasInsuranceFund = isSpot
			? true
			: marketHasInsuranceFund(info.account as PerpMarketAccount);

		// Don't show the insurance fund warning for isolated markets because they show their own warning
		const showInsuranceFundWarning =
			!hasInsuranceFund &&
			!matchEnum(info.config.oracleSource, OracleSource.Prelaunch); // Pre-Launch markets use their own insurance fund warning

		setCurrentMarketInfo({
			hasInsuranceFund: hasInsuranceFund,
			isDriftSpotMarket: isSpot && info.config.symbol.includes('DRIFT'),
			showInsuranceFundWarning,
			type: marketType,
			info,
		});
	}, [currentlySelectedMarket, allMarketsInfo?.initialized]);

	return currentMarketInfo;
};

export default singletonHook(DEFAULT_STATE, useInfoForCurrentlySelectedMarket);
