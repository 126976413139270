import { BigNum } from '@drift-labs/sdk';
import { MarketId } from '@drift/common';
import React from 'react';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import NumberDisplay from '../Utils/NumberDisplay';
import { MAX_PREDICTION_PRICE_NUM } from 'src/constants/math';
import use24hMarketDataFromStore from 'src/hooks/use24hMarketDataFromStore';

const priceChangePercentDisplay = (props: {
	marketId: MarketId;
	markPrice: BigNum;
	isSellPredictionMarket?: boolean; // mainly used for sell prediction markets
}) => {
	const dataForMarket = use24hMarketDataFromStore({
		targetMarketType: props.marketId.marketType,
		targetMarketIndex: props.marketId.marketIndex,
	})[0];

	let pricePercentChange = undefined;

	if (
		dataForMarket?.price24hAgo &&
		dataForMarket?.price24hAgo !== 0 &&
		props.markPrice &&
		!props.markPrice.eqZero()
	) {
		const price24hAgo = props.isSellPredictionMarket
			? MAX_PREDICTION_PRICE_NUM - dataForMarket.price24hAgo
			: dataForMarket.price24hAgo;
		pricePercentChange =
			100 * ((props.markPrice.toNum() - price24hAgo) / price24hAgo);
	} else if (dataForMarket?.pricePercentChange) {
		pricePercentChange = dataForMarket.pricePercentChange;
	}

	return isNaN(pricePercentChange) ? (
		<SkeletonValuePlaceholder
			className="xs:h-[11px] sm:h-[20px] my-[1px]"
			loading={true}
		/>
	) : (
		<NumberDisplay
			value={pricePercentChange}
			displayType="percentageChange"
			colourCoded
		/>
	);
};

export const PriceChangePercentDisplay = React.memo(priceChangePercentDisplay);
