import React from 'react';

import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const ProgressAnimation = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

const LoadingBarOuter = styled.div`
	display: block;
	width: 40px;
	height: 10px;
	border-radius: 5px;
	border: 1px solid;
	padding: 1px;
	background-color: transparent;
`;

const LoadingBarInner = styled.div`
	height: 100%;
	border-radius: 5px;

	animation-name: ${ProgressAnimation};
	animation-duration: 1000ms;
	animation-timing-function: ease-in-out;
	animation-play-state: running;
	animation-iteration-count: infinite;
`;

const InlineLoadingBar = () => {
	return (
		<LoadingBarOuter className="border-static-emphasized">
			<LoadingBarInner className="bg-static-emphasized" />
		</LoadingBarOuter>
	);
};

export default InlineLoadingBar;
