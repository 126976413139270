export type GridSectionConfiguration = {
	rowStart: number;
	colStart: number;
	rowSpan: number;
	colSpan: number;
};

export type OuterGridSections = {
	marketSelector?: GridSectionConfiguration; // pro only
	marginInfo?: GridSectionConfiguration; // pro only
	tradeForm?: GridSectionConfiguration; // lite only
	innerGrid: GridSectionConfiguration;
};

export type InnerGridSections = {
	tvChart: GridSectionConfiguration;
	userInfo: GridSectionConfiguration;
	marketSelector?: GridSectionConfiguration; // lite only
	recentTrades?: GridSectionConfiguration; // pro only
	tradeForm?: GridSectionConfiguration; // ... tablet only? not sure if this is even used
};

export type PerpPageGridConfigutation = {
	inner: GridConfigutation<InnerGridSections>;
	outer: GridConfigutation<OuterGridSections>;
};

export type GridConfigutation<T> = {
	rowDefs: string;
	columnDefs: string;
	sections: T;
};

export type GridConfigInfo = {
	value: string;
	label: string;
	isLiteMode: boolean;
	config: PerpPageGridConfigutation;
};

export const TradeFormWidth = '330px';
// export const TradeFormWidth = `minmax(${TradeFormMinWidth}, 1fr)`;
export const LiteTradeFormWidth = `360px`;
// export const LiteTradeFormWidth = `minmax(${LiteTradeFormMinWidth}, 1fr)`;
export const RecentTradesWidth = 'minmax(296px, 1fr)';
export const InnerGridWidth = '5fr';
export const ChartColumnWidth = 'minmax(300px, 4fr)';

export const MIRRORED_CONFIG: GridConfigInfo = {
	value: 'mirrored',
	label: 'Mirrored',
	isLiteMode: false,
	config: {
		inner: {
			columnDefs: `${RecentTradesWidth} ${ChartColumnWidth}`,
			rowDefs: `minmax(95px,2fr) auto`,
			sections: {
				tradeForm: {
					colStart: 1,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				recentTrades: {
					colStart: 1,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				tvChart: {
					colStart: 2,
					colSpan: 2,
					rowStart: 1,
					rowSpan: 1,
				},
				userInfo: {
					colStart: 1,
					colSpan: 3,
					rowStart: 2,
					rowSpan: 1,
				},
			},
		},
		outer: {
			rowDefs: 'minmax(54px,auto) 200px 1fr',
			columnDefs: `${TradeFormWidth} ${RecentTradesWidth} 4fr ${RecentTradesWidth}`,
			sections: {
				marketSelector: {
					colStart: 1,
					colSpan: 3,
					rowStart: 1,
					rowSpan: 1,
				},
				marginInfo: {
					colStart: 4,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				tradeForm: {
					colStart: 1,
					colSpan: 1,
					rowStart: 2,
					rowSpan: 2,
				},
				innerGrid: {
					colStart: 2,
					colSpan: 3,
					rowStart: 2,
					rowSpan: 2,
				},
			},
		},
	},
};

export const DEFAULT_CONFIG: GridConfigInfo = {
	value: 'default',
	label: 'Default',
	isLiteMode: false,
	config: {
		inner: {
			columnDefs: `${ChartColumnWidth} ${RecentTradesWidth}`,
			rowDefs: `minmax(95px,2fr) auto`,
			sections: {
				tradeForm: {
					colStart: 1,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				recentTrades: {
					colStart: 2,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				tvChart: {
					colStart: 1,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				userInfo: {
					colStart: 1,
					colSpan: 2,
					rowStart: 2,
					rowSpan: 1,
				},
			},
		},
		outer: {
			rowDefs: 'minmax(54px,auto) 200px 1fr',
			columnDefs: `4fr ${RecentTradesWidth} ${TradeFormWidth}`,
			sections: {
				marketSelector: {
					colStart: 1,
					colSpan: 2,
					rowStart: 1,
					rowSpan: 1,
				},
				marginInfo: {
					colStart: 3,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				tradeForm: {
					colStart: 3,
					colSpan: 1,
					rowStart: 2,
					rowSpan: 2,
				},
				innerGrid: {
					colStart: 1,
					colSpan: 2,
					rowStart: 2,
					rowSpan: 2,
				},
			},
		},
	},
};

export const SPLIT_CONFIG: GridConfigInfo = {
	value: 'split',
	label: 'Split',
	isLiteMode: false,
	config: {
		inner: {
			columnDefs: `${RecentTradesWidth} ${ChartColumnWidth}`,
			rowDefs: `minmax(95px,2fr) auto`,
			sections: {
				tradeForm: {
					colStart: 2,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				recentTrades: {
					colStart: 1,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				tvChart: {
					colStart: 2,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				userInfo: {
					colStart: 1,
					colSpan: 2,
					rowStart: 2,
					rowSpan: 1,
				},
			},
		},
		outer: {
			rowDefs: 'minmax(54px,auto) 200px 1fr',
			columnDefs: `${RecentTradesWidth} 4fr ${TradeFormWidth}`,
			sections: {
				marketSelector: {
					colStart: 1,
					colSpan: 2,
					rowStart: 1,
					rowSpan: 1,
				},
				marginInfo: {
					colStart: 3,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				tradeForm: {
					colStart: 3,
					colSpan: 1,
					rowStart: 2,
					rowSpan: 2,
				},
				innerGrid: {
					colStart: 1,
					colSpan: 2,
					rowStart: 2,
					rowSpan: 2,
				},
			},
		},
	},
};

const SPLIT_MIRRORED_CONFIG: GridConfigInfo = {
	value: 'splitMirrored',
	label: 'Split (Mirrored)',
	isLiteMode: false,
	config: {
		inner: {
			columnDefs: `${ChartColumnWidth} ${RecentTradesWidth}`,
			rowDefs: `minmax(95px,2fr) auto`,
			sections: {
				tradeForm: {
					colStart: 1,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				recentTrades: {
					colStart: 2,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				tvChart: {
					colStart: 1,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				userInfo: {
					colStart: 1,
					colSpan: 2,
					rowStart: 2,
					rowSpan: 1,
				},
			},
		},
		outer: {
			rowDefs: 'minmax(54px,auto) 200px 1fr',
			columnDefs: `${TradeFormWidth} 4fr ${RecentTradesWidth}`,
			sections: {
				marketSelector: {
					colStart: 1,
					colSpan: 2,
					rowStart: 1,
					rowSpan: 1,
				},
				marginInfo: {
					colStart: 3,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				tradeForm: {
					colStart: 1,
					colSpan: 1,
					rowStart: 2,
					rowSpan: 2,
				},
				innerGrid: {
					colStart: 2,
					colSpan: 2,
					rowStart: 2,
					rowSpan: 2,
				},
			},
		},
	},
};

const liteRowsOuter = 'minmax(54px,auto) 200px 1fr';
const liteRowsInner = 'minmax(54px,auto) minmax(95px,2fr) auto';

export const MIRRORED_LITE_CONFIG: GridConfigInfo = {
	value: 'mirroredLite',
	label: 'Mirrored',
	isLiteMode: true,
	config: {
		outer: {
			rowDefs: liteRowsOuter,
			columnDefs: `${LiteTradeFormWidth} 3fr ${RecentTradesWidth}`,
			sections: {
				tradeForm: {
					colStart: 1,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 3,
				},
				innerGrid: {
					colStart: 2,
					colSpan: 2,
					rowStart: 1,
					rowSpan: 3,
				},
			},
		},
		inner: {
			columnDefs: `${ChartColumnWidth} ${RecentTradesWidth}`,
			rowDefs: liteRowsInner,
			sections: {
				marketSelector: {
					colStart: 1,
					colSpan: 2,
					rowStart: 1,
					rowSpan: 1,
				},
				tvChart: {
					colStart: 1,
					colSpan: 2,
					rowStart: 2,
					rowSpan: 1,
				},
				userInfo: {
					colStart: 1,
					colSpan: 2,
					rowStart: 3,
					rowSpan: 1,
				},
			},
		},
	},
};

export const DEFAULT_LITE_CONFIG: GridConfigInfo = {
	value: 'defaultLite',
	label: 'Default',
	isLiteMode: true,
	config: {
		outer: {
			rowDefs: liteRowsOuter,
			columnDefs: `${RecentTradesWidth} 3fr ${LiteTradeFormWidth}`,
			sections: {
				tradeForm: {
					colStart: 3,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 3,
				},
				innerGrid: {
					colStart: 1,
					colSpan: 2,
					rowStart: 1,
					rowSpan: 3,
				},
			},
		},
		inner: {
			columnDefs: `${RecentTradesWidth} ${ChartColumnWidth}`,
			rowDefs: liteRowsInner,
			sections: {
				marketSelector: {
					colStart: 1,
					colSpan: 2,
					rowStart: 1,
					rowSpan: 1,
				},
				tvChart: {
					colStart: 1,
					colSpan: 2,
					rowStart: 2,
					rowSpan: 1,
				},
				userInfo: {
					colStart: 1,
					colSpan: 2,
					rowStart: 3,
					rowSpan: 1,
				},
			},
		},
	},
};

export const TABLET_CONFIG: GridConfigInfo = {
	value: 'defaultTablet',
	label: 'Default',
	isLiteMode: false,
	config: {
		inner: {
			columnDefs: `${TradeFormWidth} 3fr`,
			rowDefs: `minmax(650px,2fr) auto`,
			sections: {
				tradeForm: {
					colStart: 1,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				// recent trades, orderbook, and chart all use the tvChart config on tablet
				tvChart: {
					colStart: 2,
					colSpan: 1,
					rowStart: 1,
					rowSpan: 1,
				},
				userInfo: {
					colStart: 1,
					colSpan: 2,
					rowStart: 2,
					rowSpan: 1,
				},
			},
		},
		outer: {
			rowDefs: 'minmax(54px,auto) 1fr',
			columnDefs: `${TradeFormWidth} minmax(auto, 4fr)`,
			sections: {
				marketSelector: {
					colStart: 1,
					colSpan: 2,
					rowStart: 1,
					rowSpan: 1,
				},
				tradeForm: {
					colStart: 1,
					colSpan: 1,
					rowStart: 2,
					rowSpan: 1,
				},
				innerGrid: {
					colStart: 1,
					colSpan: 2,
					rowStart: 2,
					rowSpan: 1,
				},
			},
		},
	},
};

/**
 * Grid configurations for the grid layout switcher.
 * Currently these configurations only apply to the LARGE breakpoint size.
 */
const PRO_GRID_CONFIGURATIONS: GridConfigInfo[] = [
	DEFAULT_CONFIG,
	MIRRORED_CONFIG,
	SPLIT_CONFIG,
	SPLIT_MIRRORED_CONFIG,
];

const LITE_GRID_CONFIGURATIONS: GridConfigInfo[] = [
	DEFAULT_LITE_CONFIG,
	MIRRORED_LITE_CONFIG,
];

const ALL_GRID_CONFIGURATIONS = [
	...PRO_GRID_CONFIGURATIONS,
	...LITE_GRID_CONFIGURATIONS,
];

export {
	PRO_GRID_CONFIGURATIONS,
	LITE_GRID_CONFIGURATIONS,
	ALL_GRID_CONFIGURATIONS,
};
