'use client';

import { MarketId } from '@drift/common';
import useMarketsInfoStore, {
	MarketsInfoStore,
} from 'src/stores/useMarketsInfoStore';
import {
	AMM_RESERVE_PRECISION,
	BASE_PRECISION,
	PerpMarketAccount,
	SpotMarketAccount,
} from '@drift-labs/sdk';
import { PerpMarketInfo, SpotMarketInfo } from 'src/stores/types';
import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const DEFAULT_STEP_SIZE = 0.1;

export const getStepSizeFromPerpMarketAccount = (
	account: PerpMarketAccount
) => {
	const stepSizeBn = account?.amm?.orderStepSize ?? AMM_RESERVE_PRECISION;
	return stepSizeBn.toNumber() / AMM_RESERVE_PRECISION.toNumber();
};

export const getStepSizeFromSpotMarketAccount = (
	account: SpotMarketAccount
) => {
	if (!account) return DEFAULT_STEP_SIZE;

	return (
		account.orderStepSize.toNumber() /
		OrderedSpotMarkets[account.marketIndex].precision.toNumber()
	);
};

export const getMarketStepSize = (
	marketId: MarketId,
	marketInfoStoreGetter: MarketsInfoStore['get']
) => {
	const selectedMarketInfo = marketId
		? marketInfoStoreGetter().getMarketInfoByIndexAndType(
				marketId.marketIndex,
				marketId.marketType
		  )
		: undefined;

	let stepSize = DEFAULT_STEP_SIZE;

	if (!selectedMarketInfo) {
		return DEFAULT_STEP_SIZE;
	}

	if (marketId.isPerp) {
		stepSize = getStepSizeFromPerpMarketAccount(
			(selectedMarketInfo as PerpMarketInfo).account
		);
	} else {
		stepSize = getStepSizeFromSpotMarketAccount(
			(selectedMarketInfo as SpotMarketInfo).account
		);
	}

	return stepSize;
};

// Returns the step size of a market. e.g. SOL-PERP has a step size of 0.1
export const useMarketStepSize = (marketId: MarketId) => {
	const storeGetter = useMarketsInfoStore((s) => s.get);
	return getMarketStepSize(marketId, storeGetter);
};

export const useCurrentMarketStepSize = () => {
	const currentlySelectedMarket = useDriftStore(
		(s) => s.selectedMarket.current
	);
	return useMarketStepSize(currentlySelectedMarket.marketId);
};

const getMarketStepSizeBn = (
	marketId: MarketId,
	marketInfoStoreGetter: MarketsInfoStore['get']
) => {
	const selectedMarketInfo = marketId
		? marketInfoStoreGetter().getMarketInfoByIndexAndType(
				marketId.marketIndex,
				marketId.marketType
		  )
		: undefined;

	if (!selectedMarketInfo) {
		return BASE_PRECISION;
	}

	if (marketId.isPerp) {
		return (selectedMarketInfo as PerpMarketInfo).account?.amm?.orderStepSize;
	} else {
		return (selectedMarketInfo as SpotMarketInfo)?.account?.orderStepSize;
	}
};

export const useMarketStepSizeBn = (marketId: MarketId) => {
	const storeGetter = useMarketsInfoStore((s) => s.get);
	return getMarketStepSizeBn(marketId, storeGetter);
};

export const useCurrentMarketStepSizeBn = () => {
	const currentlySelectedMarket = useDriftStore(
		(s) => s.selectedMarket.current
	);
	return useMarketStepSizeBn(currentlySelectedMarket.marketId);
};
